import React from "react"
import Layout from "../components/Layout"
import TestimonialsList from "./testimonials-list.jsx"
import { PageSeo } from "../components/PageSeo"

const TestimonialsTemplate = () => {
  return (
    <Layout>
      <div className="TestimonialsTemplate">
        <TestimonialsList />
      </div>
    </Layout>
  )
}

export default TestimonialsTemplate
export const Head = () => (
  <PageSeo
    title="Testimonials | INVESTMENT MANAGEMENT SOFTWARE SOLUTION PROVIDER"
    description="TESTIMONIALS - We proud the share what our clients say about us"
    keywords="First Metro Asset Management Inc, Banco De Oro, First Metro Investment Corporation, MICHAEL SMITH, ADOR A.ABROGENA, REY DC ERLANO"
  />
)
